import React from 'react'

export const ProjectCard = () => {
	return (
		<div className={`project-card`}>
			<a href="https://www.macrumors.com">
				<button>GitHub Repo</button>
			</a>
		</div>
	)
}
