
export const AboutPage = () => {
	return (
		<>
			<h2>My Summer Vacation</h2>
			<p>
				i have experience. almost all of it, actually. you wouldn't believe how
				much experience. four years of experience. HARD years.
			</p>
		</>
	)
}
